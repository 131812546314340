import http from "./http";
import store from '../store/index';

/**
 * 通过接口
 * @type {{getBase: api.getBase}}
 */
let api = {
    /***
     * 基础数据
     * @param type
     * @param callback
     */
    getBase: function (param,callback) {
        let requestParam = null;
        if(param instanceof Object){
            requestParam = param;
        }else{
            requestParam = {type:param,audit:1};
        }
        http.post("/base/name/list",requestParam,{animation: store.state.Animation.NONE}).then(data=>{
            data = data.data;
            if(data){
                callback(data);
            }
        })
    },
    /***
     * 基础数据
     * @param type
     * @param callback
     */
    getMoney: function (types,callback) {
        http.post("/base/money/get",{types:types,audit:1},{animation: store.state.Animation.NONE}).then(data=>{
            data = data.data;
            if(data){
                callback(data);
            }
        })
    },
    /***
     * 基础数据
     * @param type
     * @param callback
     */
    getMoneyByParam: function (param,callback) {
        if(!param.audit){
            param.audit = 1;
        }
        http.post("/base/money/get",param,{animation: store.state.Animation.NONE}).then(data=>{
            data = data.data;
            if(data){
                callback(data);
            }
        })
    },
    /***
     * 树型数据
     * @param param
     * @param callback
     */
    getTree: function (param,callback) {
        let requestParam = null;
        if(param instanceof Object){
            requestParam = param;
        }else{
            requestParam = {type:param,audit:1};
        }
        http.post("/base/tree/list",requestParam,{animation: store.state.Animation.NONE}).then(data=>{
            data = data.data;
            if(data){
                callback(data);
            }
        })
    },
    /***
     * 树型数据
     * @param type
     * @param callback
     */
    getUser: function (ids,callback) {
        if(!ids||ids.length<1){
            return;
        }
        http.post("/user/getSimpleUserByIds",ids,{headers: {"Content-Type": 'application/json;charset=utf-8'},animation: store.state.Animation.NONE}).then(data=>{
            data = data.data;
            if(data){
                callback(data);
            }
        })
    },
    /***
     * 生成编号
     * @param type
     * @param callback
     */
    randomCode: function (callback) {
        http.post("/config/getRandomCode",{},{animation: store.state.Animation.NONE}).then(data=>{
            data = data.data;
            if(data){
                callback(data);
            }
        })
    },
    /***
     * 预上传文件
     * @param type
     * @param callback
     */
    preUpload: function (file,callback) {
        let formDate = new FormData();
        formDate.append("file",file);
        http.post("/file/pre/upload",formDate,{headers: {"Content-Type": 'multipart/form-data'}}).then(data=>{
            if(data.code===200){
                callback(data.data);
            }else{
                throw new Error(data.msg);
            }
        })
    },
    /***
     * 预上传文件
     * @param type
     * @param callback
     */
    getFileContent: function (id,callback) {
        http.get("/file/get/"+id,{}).then(data=>{
            callback(data);
        })
    },
    /***
     * 任务日志
     * @param key
     */
    getTaskLog(key,logs,logList,callback,endCallback,logDone,logsCount) {
        if(!logsCount){
            logsCount = 300;
        }
        if(logsCount<=0){
            logs.push("无法获取结果");
            return;
        }
        let time = +new Date();
        if(!window.timers){
            window.timers = {};
        }
        if(window.timers){
            window.timers[key+""] = time;
        }
        http.post("/client/task/get/ing", {key: key}, {ignoreError:true,animation: store.state.Animation.NONE}).then(data => {
            if (data.code !== store.state.ResultCode.OK) {
                logs.push(data);
                if(logList){
                    setTimeout(()=>{
                        logList.scrollTop = logList.scrollHeight;
                    },200);
                }
                return;
            } else {
                let done = false;
                data = data.data;
                if(!data||data.length<1){
                    done = false;
                    logsCount--;
                }else{
                    for (let i = 0; i < data.length; i++) {
                        logs.push(data[i]);
                        if(callback&&data[i].param){
                            callback(data[i].param);
                        }
                        if (logList) {
                            setTimeout(() => {
                                logList.scrollTop = logList.scrollHeight;
                            }, 200);
                        }
                        if (data[i].state===2) {
                            done = true;
                            if(endCallback){
                                endCallback();
                            }
                        }else if(data[i].state===3){
                            callback(data[i]);
                        }else if(data[i].state===4){
                            if(logDone){
                                logDone[2] = 1;
                            }
                        }
                    }
                }
                //未结束,未收到状态2#
                //已结束,收到状态2#
                //额外标记已完成#
                //额外标记不存在#
                //额外标记未完成#
                //额外标记已完成#
                //额外标记数量小于等于5#
                //额外标记数量大于5#
                if(logDone){
                    if(logDone[0]){
                        if(isNaN(logDone[1])){
                            logDone[1] = 0;
                        }
                        if(logDone[2]){
                            logDone[1] = -120;
                            logDone[2] = 0;
                        }
                        if(logDone[1]>50){
                            //额外标记不存在
                            //额外标记已完成
                            //额外标记数量大于50
                            logs.push({msg:'日志跟踪结束',level:'info'})
                            return;
                        }
                        //额外标记数量小于等于50
                        logDone[1]++;
                        setTimeout(() => {
                            if(window.timers[key+""]===time){
                                this.getTaskLog(key, logs, logList,callback,endCallback,logDone, logsCount);
                            }else{
                                logs.push({msg:'日志跟踪更替',level:'info'})
                            }
                        }, 2000)
                        return;
                    }else{
                        logDone[1] = 0;
                        //额外标记未完成
                        setTimeout(() => {
                            if(window.timers[key+""]===time) {
                                this.getTaskLog(key, logs, logList, callback, endCallback, logDone, logsCount);
                            }else{
                                logs.push({msg:'日志跟踪更替',level:'info'})
                            }
                        }, 2000)
                        return;
                    }
                }
                //额外标记不存在
                if (!done) {
                    //未结束,未收到状态2
                    setTimeout(() => {
                        if(window.timers[key+""]===time) {
                            this.getTaskLog(key, logs, logList, callback, endCallback, logDone, logsCount);
                        }else{
                            logs.push({msg:'日志跟踪更替',level:'info'})
                        }
                    }, 2000)
                }else{
                    logs.push({msg:'日志跟踪结束',level:'info'})
                }
                //已结束,收到状态2
            }
        })
    },
};
export default api;