<template>
    <div>
        <div class="crumbs">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>
                    <i class="el-icon-lx-cascades"></i> <span v-if="client.id">[{{client.name}}]</span>服务列表
                </el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="container">
            <div class="handle-box">
                <el-button style="margin-right: 5px" v-if="hasAuth('SaveClientServer')"
                           type="primary"
                           icon="el-icon-plus"
                           class="handle-del"
                           @click="addClientServer"
                >添加客户端服务
                </el-button>
                <el-popover placement="top-start" trigger="hover" content="服务">
                    <template #reference>
                        <el-select v-model="query.serverId" class="handle-select" placeholder="服务">
                            <el-option key="" label="全部" value=""></el-option>
                            <el-option :key="item.id" :label="item.name" :value="item.id"
                                       v-for="item in servers"></el-option>
                        </el-select>
                    </template>
                </el-popover>
                <el-popover placement="top-start" trigger="hover" content="客户端" v-if="!client.id">
                    <template #reference>
                        <el-select v-model="query.clientId" class="handle-select"
                                   remote
                                   filterable
                                   default-first-option
                                   :remote-method="searchClient"
                                   placeholder="请输入名称/mac/ip"
                                   @focus="searchClient('')"
                                   :clearable="true"
                                   :loading="loading"
                        >
                            <el-option
                                    v-for="item in clients"
                                    :key="item.id"
                                    :label="item.name+'/'+item.ip"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                    </template>
                </el-popover>
                <el-popover placement="top-start" trigger="hover" content="状态">
                    <template #reference>
                        <el-select v-model="query.state" placeholder="状态" class="handle-select">
                            <el-option key="" label="全部" value=""></el-option>
                            <el-option key="0" label="离线" :value="0"></el-option>
                            <el-option key="1" label="正常" :value="1"></el-option>
                        </el-select>
                    </template>
                </el-popover>
                <el-button type="primary" icon="el-icon-search" @click="handleSearch">搜索</el-button>
            </div>
            <el-table
                    :data="tableData"
                    border
                    class="table"
                    :height="common.calcTableHeight(350)"
                    header-cell-class-name="table-header"
            >
                <el-table-column type="index" label="序号" width="55" align="center"></el-table-column>
                <el-table-column prop="clientId" label="客户端" align="center" width="150">
                    <template #default="scope">
                        <span>{{common.arrayFindReturn2(clients,scope.row.clientId,"id","name,ip")}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="name" label="名称" align="center"></el-table-column>
                <el-table-column prop="serverId" label="服务" align="center">
                    <template #default="scope">
                        <span>{{common.arrayFindReturn(servers,scope.row.serverId)}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="ports" label="服务端口号" align="center">
                    <template #default="scope">
                        <span v-if="!scope.row.ports || scope.row.ports.length<1">无端口</span>
                        <div v-else>
                        <span v-for="(item,index) in scope.row.ports" :key="index"><span v-if="index>0">,</span>
                            <span :class="{success:scope.row.runPorts&&scope.row.runPorts.indexOf(item)>=0}">{{item}}</span>
                        </span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="count" label="运行实例数" align="center">
                    <template #default="scope">
                        <span title="运行中/实例数">{{scope.row.runCount}}/{{scope.row.count}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="状态" align="center">
                    <template #default="scope">
                        <span v-if="scope.row.state===1" class="success">启用</span>
                        <span v-if="scope.row.state===0" class="error">禁用</span>
                    </template>
                </el-table-column>
                <el-table-column label="心跳状态" align="center" width="180">
                    <template #default="scope">
                        <span v-if="scope.row.healthState===1" class="success">正常</span>
                        <span v-else-if="scope.row.type==='web'">无状态</span>
                        <span v-else class="error">离线</span>
                        <br/>
                        <span v-if="scope.row.refreshDate && scope.row.refreshDate>0">{{scope.row.refreshDate.toDate('yyyy-MM-dd HH:mm:ss')}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="version" label="当前版本" align="center">
                    <template #default="scope">
                        {{scope.row.version||'无版本'}}
                    </template>
                </el-table-column>
                <el-table-column prop="versionDate" label="版本更新时间" align="center">
                    <template #default="scope">
                        <span v-if="scope.row.versionDate && scope.row.versionDate>0">{{scope.row.versionDate.toDate('yyyy-MM-dd')}}</span>
                        <span v-else>无更新</span>
                    </template>
                </el-table-column>
                <el-table-column fixed="right" label="操作" class-name="table-op" width="260" align="center"
                                 v-if="hasAuth('UpdateClientServer','DeleteClientServer')">
                    <template #default="scope">
                        <el-button
                                type="text"
                                icon="el-icon-view"
                                @click="scope.row.edit=false,handleEdit(scope.$index, scope.row)">查看
                        </el-button>
                        <el-button v-if="hasAuth('SaveClientServerVersion')"
                                type="text"
                                class="primary"
                                icon="el-icon-upload"
                                @click="$router.push({path:'/client/server/version/edit',query:{serverId:scope.row.serverId,type:scope.row.type,clientServerId:scope.row.id}})">更新
                        </el-button>
                        <el-dropdown  trigger="click">
                            <span class="el-dropdown-link" style="margin-left: 10px"><i class="el-icon-paperclip"></i> 更多</span>
                            <template #dropdown>
<!--                                <el-dropdown-menu>-->
                                <ul>
                                    <el-dropdown-item v-if="scope.row.type!=='web'"
                                                      icon="el-icon-upload2"
                                                      @click="ssServer(scope.row,'start')">启动
                                    </el-dropdown-item>
                                    <el-dropdown-item v-if="scope.row.healthState===1 && scope.row.type!=='web'"
                                                      icon="el-icon-download"
                                                      class="red"
                                                      @click="ssServer(scope.row,'stop')">停止
                                    </el-dropdown-item>
                                    <el-dropdown-item v-if="hasAuth('UpdateClientServer')"
                                                      icon="el-icon-edit"
                                                      @click="scope.row.edit=true,handleEdit(scope.$index, scope.row)">编辑
                                    </el-dropdown-item>
                                    <el-dropdown-item v-if="hasAuth('DeleteClientServer')"
                                                      icon="el-icon-delete"
                                                      class="red"
                                                      @click="handleDelete(scope.$index, scope.row)">删除
                                    </el-dropdown-item>
                                </ul>
<!--                                </el-dropdown-menu>-->
                            </template>
                        </el-dropdown>
                    </template>
                </el-table-column>
            </el-table>
            <div class="pagination">
                <el-pagination
                        background
                        layout="total, prev, pager, next"
                        :current-page="query.page"
                        :page-size="query.rows"
                        :total="pageTotal"
                        @current-change="handlePageChange"
                ></el-pagination>
            </div>
        </div>

        <!-- 编辑弹出框 -->
        <el-dialog :title="(!form.edit?'查看':form.id!=null?'编辑':'添加')+'客户端服务'" v-model="editVisible" width="1000px"
                   :close-on-click-modal="false">
            <el-form ref="form" :model="form" label-width="100px" aria-autocomplete="none">
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="客户端" required>
                            <span v-if="!form.edit">{{common.arrayFindReturn2(clients,form.clientId,"id","name,ip")}}</span>
                            <el-select v-else v-model="form.clientId" class="form-item"
                                       :disabled="client.id!=null||form.id!=null"
                                       remote
                                       filterable
                                       default-first-option
                                       :remote-method="searchClient"
                                       placeholder="请输入名称/mac/ip"
                                       @focus="searchClient('')"
                                       :clearable="true"
                                       :loading="loading"
                            >
                                <el-option
                                        v-for="item in clients"
                                        :key="item.id"
                                        :label="item.name+'/'+item.ip"
                                        :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="名称" required>
                            <span v-if="!form.edit">{{form.name}}</span>
                            <el-input v-else class="form-item" v-model="form.name" placeholder="名称"
                                      :clearable="true"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="服务" required>
                            <span v-if="!form.edit">{{common.arrayFindReturn(servers,form.serverId)}}</span>
                            <el-select v-else v-model="form.serverId" class="form-item" placeholder="服务" @change="selectServer">
                                <el-option :key="item.id" :label="item.name" :value="item.id"
                                           v-for="item in servers"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="部署方式" required>
                            <span v-if="!form.edit">{{form.type}}</span>
                            <el-select v-else class="form-item" v-model="form.type">
                                <el-option key="jar" label="jar" value="jar"></el-option>
                                <el-option key="war" label="war" value="war"></el-option>
                                <el-option key="web" label="web" value="web"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8" v-if="form.type!=='web'">
                        <el-form-item label="服务端口号">
                            <span v-if="!form.edit"> <span v-if="form.ports"><span v-for="(item,index) in form.ports"
                                                                                   :key="index"><span
                                    v-if="index>0">,</span>
                                <span :class="{success:form.runPorts&&form.runPorts.indexOf(item)>=0}">{{item}}</span>
                                </span></span></span>
                            <el-input v-else class="form-item" v-model="form.ports" placeholder="服务端口号" @change="checkPort('ports')"
                                      :clearable="true"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8" v-if="form.type==='war'">
                        <el-form-item label="关闭端口号">
                            <span v-if="!form.edit"> <span v-if="form.closePorts"><span
                                    v-for="(item,index) in form.closePorts" :key="index"><span v-if="index>0">,</span>
                            <span :class="{success:form.runClosePorts&&form.runClosePorts.indexOf(item)>=0}">{{item}}</span>
                            </span></span></span>
                            <el-input v-else class="form-item" v-model="form.closePorts" placeholder="关闭端口号" @change="checkPort('closePorts')"
                                      :clearable="true"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="运行实例数">
                            <span v-if="!form.edit">{{form.runCount}}/{{form.count}}</span>
                            <el-input-number :min="1" :max="20" v-else :precision="0" class="form-item" v-model="form.count"
                                             placeholder="运行实例数"
                                             :clearable="true"></el-input-number>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8" v-if="form.type!=='web'">
                        <el-form-item label="数据库驱动">
                            <span v-if="!form.edit">{{form.jdbcDriver}}</span>
                            <el-select v-else v-model="form.jdbcDriver"  class="form-item" placeholder="数据库驱动">
                                <el-option :key="item.other" :label="item.name" :value="item.other"
                                           v-for="item in bases.jdbcDriver"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8" v-if="form.type!=='web'">
                        <el-form-item label="数据库用户名">
                            <span v-if="!form.edit">{{form.jdbcUsername}}</span>
                            <el-input v-else class="form-item" v-model="form.jdbcUsername" placeholder="数据库用户名"
                                      :clearable="true"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8" v-if="form.type!=='web'">
                        <el-form-item label="数据库密码">
                            <span v-if="!form.edit">{{form.jdbcPassword}}</span>
                            <el-input v-else class="form-item" v-model="form.jdbcPassword" placeholder="数据库用户名"
                                      :clearable="true"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24" v-if="form.type!=='web'">
                        <el-form-item label="数据库连接">
                            <span v-if="!form.edit">{{form.jdbcUrl}}</span>
                            <el-input v-else class="form-item full" v-model="form.jdbcUrl" placeholder="数据库连接"
                                      :clearable="true"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="状态" required>
                            <span v-if="!form.edit">{{form.state===1?'启用':'禁用'}}</span>
                            <el-select v-else class="form-item" v-model="form.state">
                                <el-option key="1" label="启用" :value="1"></el-option>
                                <el-option key="-1" label="禁用" :value="-1"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8" v-if="form.type!=='web'">
                        <el-form-item label="自动修复" required>
                            <span v-if="!form.edit">{{form.repairState===1?'启用':'禁用'}}</span>
                            <el-select v-else class="form-item" v-model="form.repairState">
                                <el-option key="1" label="启用" :value="1"></el-option>
                                <el-option key="0" label="禁用" :value="0"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="16" v-if="form.type==='war'">
                        <el-form-item label="容器路径" required>
                            <span v-if="!form.edit">{{form.containerPath}}</span>
                            <el-input v-else class="form-item full" v-model="form.containerPath" placeholder="容器所在路径"
                                      :clearable="true"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="16" v-if="form.type==='jar'">
                        <el-form-item label="虚拟机路径">
                            <span v-if="!form.edit">{{form.containerPath}}</span>
                            <el-input v-else class="form-item full" v-model="form.containerPath" placeholder="虚拟机所在路径,为空时使用客户端相同虚拟机,例:D:\Program Files\Java\jre1.8.0_261"
                                      :clearable="true"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="16">
                        <el-form-item label="项目路径" required>
                            <span v-if="!form.edit">{{form.path}}</span>
                            <el-input v-else class="form-item full" v-model="form.path" placeholder="项目所在路径"
                                      :clearable="true"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row v-if="!form.edit">
                    <el-col :span="8">
                        <el-form-item label="心跳状态">
                            <span v-if="form.healthState===1" class="success">正常</span>
                            <span v-else-if="form.type==='web'">无状态</span>
                            <span v-else class="error">离线</span>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="最近心跳时间">
                            <span v-if="form.refreshDate && form.refreshDate>0">{{form.refreshDate.toDate('yyyy-MM-dd HH:mm:ss')}}</span>
                            <span v-else>无记录</span>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row v-if="!form.edit">
                    <el-col :span="8">
                        <el-form-item label="当前版本">
                            <span>{{form.version||'无版本'}}</span>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="最后更新时间">
                            <span v-if="form.versionDate && form.versionDate>0">{{form.versionDate.toDate('yyyy-MM-dd HH:mm:ss')}}</span>
                            <span>无更新</span>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="editVisible = false">取 消</el-button>
                    <el-button type="primary" @click="saveEdit">确 定</el-button>
                </span>
            </template>
        </el-dialog>
        <!-- 编辑弹出框 -->
        <el-dialog :title="(logObj.form.type==='start'?'启动':'停止')+'服务'" v-model="logObj.visible" width="800px"
        :close-on-click-modal="false">
            <el-form ref="opForm" :model="logObj.form" label-width="100px" aria-autocomplete="none">
                <el-form-item :label="'选择'+(logObj.form.type==='start'?'启动':'停止')+'端口'">
                    <el-checkbox-group v-model="logObj.form.ports">
                        <el-checkbox :disabled="logObj.form.type==='start'?(logObj.form.runPorts&&logObj.form.runPorts.indexOf(item)>=0):(!logObj.form.runPorts||logObj.form.runPorts.indexOf(item)<0)" :label="item" v-for="(item,index) in logObj.form.allPorts" :key="index"></el-checkbox>
                    </el-checkbox-group>
                </el-form-item>
                <el-button type="primary" @click="ssClientServer" :disabled="logObj.ing||!logObj.form.ports||logObj.form.ports.length<1">{{(logObj.form.type==='start'?'启动':'停止')}}指定端口</el-button>
                <br/>
                <br/>
                <span>操作日志</span>
                <ul ref="logs" class="scan-log" style="height:200px;border: 1px solid #EEE;padding:5px;overflow: auto">
                    <li v-for="(log,index) in logObj.logs" class="infinite-list-item" :key="index" :class="{error:log.level==='error'}">{{log.msg}}</li>
                </ul>
                <div v-if="logObj.form.packageType==='war'">会执行tomcat,bin目录下的startup和shutdown脚本进行启停</div>
                <div v-if="logObj.form.packageType==='jar'">默认查找同服务目录startup和shutdown脚本,如没有脚本会直接进行命令启停</div>
            </el-form>

            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="logObj.visible = false,getData()">取 消</el-button>
                </span>
            </template>
        </el-dialog>
    </div>
</template>

<script>
    import http from "../../utils/http";
    import common from "../../utils/common";
    import store from "../../store";
    import api from "../../utils/api";

    export default {
        name: "ClientServerList",
        data() {
            return {
                hasAuth: common.hasAuth,
                common: common,
                tableData: [],
                editVisible: false,
                authVisible: false,
                form: {},
                authForm: {
                    Client: null,
                    checkedAuth: [],
                },
                auth: [],
                pageTotal: 0,
                query: {
                    role: '',
                    name: null,
                    username: null,
                    state: null,
                    page: 1,
                    rows: 30
                },
                loading: false,
                servers: [],
                clients: [],
                client: {id: null},
                logObj: {
                    logs: [],
                    form: {ports: []},
                    visible: false,
                    ing:false,
                },
                bases:{jdbcDriver:[]},
            };
        },
        created() {
            this.init();
            this.getServer();
            api.getBase("jdbc_driver",(data)=>{
                this.bases.jdbcDriver = data;
            })
        },
        methods: {
            init(){
                this.client.id = this.$route.query.clientId;
                if(this.client.id){
                    this.client.id = parseInt(this.client.id);
                }
                if (this.client.id) {
                    this.searchClient({id: this.client.id}, (data) => {
                        if (data) {
                            this.client = data;
                        }
                    });
                }
                this.getData();
            },
            getData() {
                if (this.client.id) {
                    this.query.clientId = this.client.id;
                }
                http.post("/client/server/list", this.query).then(data => {
                    data = data.data;
                    if (data) {
                        this.tableData = data.rows;
                        this.pageTotal = data.total;
                        if (this.pageTotal > 0) {
                            this.getClientByIds(common.getIds(this.tableData, "clientId"));
                        }
                    }
                }).catch(e => {
                    this.$message.error(e.message);
                })
            },
            //获取服务
            getServer() {
                http.post("/server/list").then(data => {
                    data = data.data;
                    if (data) {
                        this.servers = data;
                    }
                })
            },
            //获取客户端
            searchClient(param, callback) {
                if (!(param instanceof Object)) {
                    param = {key: param};
                }
                http.post("/client/list", param, {animation: store.state.Animation.NONE}).then(data => {
                    data = data.data;
                    if (data) {
                        if (callback) {
                            callback(data);
                            this.clients.push(data)
                            return;
                        }
                        this.clients = data.rows;
                    }
                })
            },
            //获取客户端
            getClientByIds(ids) {
                http.post("/client/get/ids", ids, {
                    headers: {"Content-Type": 'application/json;charset=utf-8'},
                    animation: store.state.Animation.NONE
                }).then(data => {
                    data = data.data;
                    if (data) {
                        this.clients = data;
                    }
                })
            },
            // 触发搜索按钮
            handleSearch() {
                this.query.page = 1;
                this.getData();
            },
            //查找数据内容
            findArray(arr, id) {
                return common.arrayFindReturn(arr, id);
            },
            // 删除操作
            handleDelete(index, row) {
                // 二次确认删除
                this.$confirm("确定要删除吗？", "提示", {
                    type: "warning"
                })
                    .then(() => {
                        http.post("/client/server/delete", {id: row.id}).then(data => {
                            if (data.code === this.$store.state.ResultCode.OK) {
                                this.$message.success("删除成功");
                                this.getData();
                                return;
                            }
                            this.$message.error(data.msg);
                        }).catch(e => {
                            this.$message.error(e.message);
                        })
                    })
                    .catch(() => {
                    });
            },
            addClientServer() {
                this.form = {edit: true, clientId: this.client.id, state: 1};
                this.editVisible = true;
            },
            //选择服务
            selectServer(id){
               let server = common.arrayFindReturnObj(this.servers,id);
               if(server){
                   if(server.count){
                       this.form.count = server.count;
                   }
                   if(server.ports){
                       try{
                           this.form.ports = JSON.parse(server.ports).join(",");
                       }catch (e) {
                           console.log(e)
                       }
                   }
               }

            },
            // 编辑操作
            handleEdit(index, row) {
                let ports = null;
                let closePorts = null;
                if (row.ports) {
                    ports = row.ports.join(",");
                }
                if (row.closePorts) {
                    closePorts = row.closePorts.join(",");
                }
                this.form = Object.assign({}, row);
                if (row.edit) {
                    this.form.ports = ports;
                    this.form.closePorts = closePorts;
                }
                this.editVisible = true;
            },
            // 保存编辑
            saveEdit() {
                if (this.form.ports) {
                    this.form.ports = this.form.ports.replace(/，/g, ",");
                }
                http.post(this.form.id ? '/client/server/update' : '/client/server/save', this.form, {alertError: true}).then(data => {
                    if (data.code === this.$store.state.ResultCode.OK) {
                        this.editVisible = false;
                        this.getData();
                        this.$message.success(`操作成功`);
                        return;
                    }
                    this.$message.error(data.msg);
                }).catch(e => {
                    this.$message.error(e.message);
                })

            },
            // 分页导航
            handlePageChange(val) {
                this.query.page = val;
                this.getData();
            },
            //启动服务
            ssServer(row,type) {
                this.form.prots="";
                this.logObj.form.allPorts = row.ports;
                this.logObj.form.ports = [];
                this.logObj.form.runPorts = row.runPorts;
                this.logObj.form.clientServerId = row.id;
                this.logObj.form.type = type;
                this.logObj.form.packageType = row.type;
                this.logObj.visible = true;
            },
            //启动服务
            ssClientServer() {
                if(this.logObj.ing){
                   return;
                }
                this.logObj.ing = true;
                http.post(this.logObj.form.type==='start'?"/client/task/server/start":"/client/task/server/stop", [this.logObj.form],{headers: {"Content-Type": 'application/json;charset=utf-8'}}).then(data => {
                    if (data.code === this.$store.state.ResultCode.OK) {
                        this.$message.success(`操作成功`);
                        api.getTaskLog(data.data, this.logObj.logs, this.$refs.logs, (data) => {
                            this.scanData.push(data);
                        },()=>{
                            this.logObj.ing = false;
                        });
                        return;
                    }
                    this.$message.error(data.msg);
                    this.logObj.ing = false;
                }).catch(e => {
                    this.$message.error(e.message);
                    this.logObj.ing = false;
                })
            },
            //检查端口格式
            checkPort(it){
                this.form[it] = this.form[it].replace(/[， ]/g,",").replace(/[^0-9,]/g,'');
            }
        },
        watch: {
            $route() {
                if (this.$route.name === "ClientServerList") {
                    //初始化
                    this.init();
                }
            }
        },
    };
</script>

<style scoped>
    .handle-box {
        margin-bottom: 20px;
    }

    .handle-select {
        width: 120px;
    }

    .handle-input {
        width: 120px;
        display: inline-block;
    }

    .table {
        width: 100%;
        font-size: 14px;
    }
</style>
